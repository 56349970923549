import styled from 'styled-components'

export const ModalVideoWrapper = styled.div`
  .modal-video,
  .modal-video-movie-wrap iframe {
    background-color: #000;
  }

  .modal-video-body {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: none;
  }

  .modal-video-movie-wrap {
    position: static;
  }

  .modal-video-close-btn {
    right: 24px;
    top: 24px;
    height: 24px;
    width: 24px;
  }

  .modal-video-close-btn::before,
  .modal-video-close-btn::after {
    margin-top: 0;
  }

  @media (max-width: 769px) {
    .modal-video-movie-wrap {
      height: 74vh
    }
`
