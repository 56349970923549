import styled from 'styled-components'

import { Button } from '@syconium/little-miss-figgy'

const buttonLetterSpacing = '1px'

// Wrapper whose margins can be set without breaking the grid layout
export const Body = styled.div`
  max-width: 400px;
`

// button styling assumes LMF Button
export const Grid = styled.div`
  margin: 0 -7px -12px;

  ${Button} {
    box-sizing: border-box;
    letter-spacing: ${buttonLetterSpacing};
    margin: 0 7px 12px;
    min-width: 128px;
    padding: 12px calc(20px - ${buttonLetterSpacing}) 12px 20px;
  }
`
