import styled from 'styled-components'

import { ImageShrink, fromMd, untilMd } from '@syconium/little-miss-figgy'

export const Body = styled.div`
  > img {
    max-width: 100%;
  }
`

export const ImageDesktop = styled(ImageShrink)`
  ${untilMd} {
    display: none;
  }
`
export const ImageMobile = styled(ImageShrink)`
  ${fromMd} {
    display: none;
  }
`
