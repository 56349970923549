import Head from 'next/head'
import React from 'react'

import { useFixturesContext } from '../../brunswick/containers/fixtures'

import { useLocalizedMeta } from './useLocalizedMeta'
import { getPageTitle } from './utils'

export const SeoMeta: React.FC<{
  canonicalUrl?: string
  description?: string
  errorPage?: boolean
  image?: string
  imageAlt?: string
  noindex?: boolean
  title?: string
  type?: string
  video?: string
}> = ({ canonicalUrl, description, image, imageAlt, noindex, title, type = 'website', video }) => {
  const {
    globals: {
      description: globalDescription,
      facebookAppID,
      metaImage: globalImage,
      socialMediaHandles,
      siteName,
    },
  } = useFixturesContext()

  if (!!canonicalUrl && !canonicalUrl.match(/^https:\/\//)) throw 'invalid canonicalUrl'

  const { getAlternateLinks, getLocalizedCanonicalUrl, formattedLocale } = useLocalizedMeta()
  const pageTitle = getPageTitle(title, siteName)
  const ogDescription = description || globalDescription
  const ogImage = image?.replace(/^\/\//, 'https://') || globalImage
  const ogVideo = video?.replace(/^\/\//, 'https://')

  return (
    <Head>
      <title key='title-tag'>{pageTitle}</title>
      <link rel='canonical' href={getLocalizedCanonicalUrl(canonicalUrl)} key='canonical' />
      {noindex && <meta name='robots' content='noindex' key='robots' />}
      <meta name='description' content={ogDescription} key='description' />
      <meta property='fb:app_id' content={facebookAppID} key='fb:app_id' />
      <meta property='og:title' content={pageTitle} key='og:title' />
      <meta property='og:type' content={type} key='og:type' />
      <meta property='og:image' content={ogImage} key='og:image' />
      {video && <meta property='og:video' content={ogVideo} key='og:video' />}
      <meta property='og:url' content={canonicalUrl} key='og:url' />
      <meta property='og:description' content={ogDescription} key='og:description' />
      <meta property='og:locale' content={formattedLocale} key='og:locale' />
      <meta property='og:site_name' content={siteName} key='og:site_name' />
      <meta name='twitter:card' content='summary_large_image' key='twitter:card' />
      <meta name='twitter:site' content={socialMediaHandles.twitter} key='twitter:site' />
      {imageAlt && <meta name='twitter:image:alt' content={imageAlt} key='twitter:image:alt' />}
      {getAlternateLinks(canonicalUrl).map((item, index) => {
        return (
          <link
            rel={item.rel}
            hrefLang={item.hreflang}
            href={item.href}
            key={`alternate-${index}`}
          />
        )
      })}
    </Head>
  )
}
