import React from 'react'

import { Body, ImageDesktop, ImageMobile } from './styles'

export const TitleImage: React.FC<
  {
    mobileSrc: string | null
    desktopSrc: string | null
  } & React.ImgHTMLAttributes<HTMLImageElement>
> = ({ desktopSrc, mobileSrc, className, ...rest }) => {
  if (!(desktopSrc && mobileSrc)) return null

  return (
    <Body {...{ className }}>
      <ImageDesktop scale={0.5} src={desktopSrc} {...rest} />
      <ImageMobile scale={0.5} src={mobileSrc} {...rest} />
    </Body>
  )
}
