import Head from 'next/head'
import React, { ComponentProps } from 'react'

import { Button, ResponsiveImage, ViewportSizes, useIsViewport } from '@syconium/little-miss-figgy'
import { VideoModal } from '@syconium/magnolia/src/brunswick/components/VideoModal'
import { usePageSectionContext } from '@syconium/magnolia/src/components/page-sections/PageSectionContext'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import { isUppercase } from '@syconium/magnolia/src/lib/utils'
import { InlineVideoPlatforms } from '@syconium/magnolia/src/types/video'

import { VideoSectionFragmentFragment } from '../../../../__generated__/graphql/catalog/graphql'
import { MaybeSpaLinkWrapper } from '../../../../lib/adapters/next-routing-service/MaybeSpaLinkWrapper'
import { useResponsiveVideoSrc } from '../../../../lib/hooks/useResponsiveVideoSrc'
import { TitleImage } from '../../atoms/TitleImage'
import { MaxIterationsVideo } from '../../MaxIterationsVideo'
import { VimeoVideo } from '../../VimeoVideo'

import { AssetWrap, Body, StyledButtonBar, Subtitle, TextWrap, Title } from './styles'

const PREFFERED_INLINE_VIDEO_PLATFORM = InlineVideoPlatforms.VIMEO

const VideoImage: React.FC<{
  alt: string
  imageDesktop: string | null
  imageMobile: string | null
  loading: 'eager' | 'lazy' | undefined
  fetchpriority: 'high' | 'low' | 'auto' | undefined
}> = ({ alt, imageDesktop, imageMobile, loading, fetchpriority }) => {
  const imageSrc = imageDesktop ?? imageMobile

  if (!imageSrc) return null

  const srcs =
    imageDesktop && imageMobile
      ? {
          sm: imageMobile,
          md: imageDesktop,
        }
      : undefined

  return (
    <ResponsiveImage
      src={imageSrc}
      alt={alt}
      aspectRatios={{
        sm: null,
        md: null,
      }}
      loading={loading === 'lazy' ? 'lazy' : 'default'}
      fetchpriority={fetchpriority}
      widths={{ unit: 'vw', sm: 100, md: 100 }}
      srcs={srcs}
      renderPreloadLink={
        fetchpriority === 'high'
          ? preloadLink => {
              return <Head>{preloadLink}</Head>
            }
          : undefined
      }
    />
  )
}

export type VideoSectionProps = VideoSectionFragmentFragment & {
  isCriticalVideo: boolean
}

export const VideoSection: React.FC<VideoSectionProps> = ({
  buttonVariant: propButtonVariant,
  imageDesktop,
  imageMobile,
  inlineVideoDesktop,
  inlineVideoMobile,
  inlineVimeoIdDesktop,
  inlineVimeoIdMobile,
  linkHref1,
  linkHref2,
  linkText1,
  linkText2,
  modalVideoId,
  modalVideoPlatform,
  subtitle,
  textColor,
  textContentMaxWidthDesktop,
  textContentMaxWidthMobile,
  title,
  titleImageDesktop,
  titleImageMobile,
  isCriticalVideo,
}) => {
  const isMediumOrLargeViewport = useIsViewport([ViewportSizes.medium, ViewportSizes.large])
  const isSmallViewport = !isMediumOrLargeViewport

  const alt = `${title ?? ''} ${subtitle ?? ''}`

  // We have to cast this because in Contentful Gateway it is typed as a string.
  // It is typed that way because it does not follow GraphQl requirements for a valid enum syntax
  const buttonVariant = (propButtonVariant ?? 'black-on-white') as ComponentProps<
    typeof Button
  >['variant']

  const hasModalVideo = Boolean(modalVideoId && modalVideoPlatform)

  const hasButtonBar = (linkText1 && (hasModalVideo || linkText2)) || (linkText2 && linkHref2)

  const [isVideoModalOpen, setIsVideoModalOpen] = React.useState<boolean>(false)

  const { pageSectionIndex: pageSectionIndex } = usePageSectionContext()

  const openVideoModal = React.useCallback(() => {
    if (hasModalVideo) setIsVideoModalOpen(true)
  }, [hasModalVideo])

  const closeVideoModal = React.useCallback(() => {
    setIsVideoModalOpen(false)
  }, [])

  // TODO inlinevideo=false forces fallback to image asset

  const displayImage =
    (isMediumOrLargeViewport && !(inlineVideoDesktop || inlineVimeoIdDesktop)) ||
    (isSmallViewport && !(inlineVideoMobile || inlineVimeoIdMobile))

  const video = useResponsiveVideoSrc({
    inlineVideoDesktop,
    inlineVideoMobile,
    inlineVimeoIdDesktop,
    inlineVimeoIdMobile,
    preferredVideoPlatform: PREFFERED_INLINE_VIDEO_PLATFORM,
  })

  return (
    <Body>
      <AssetWrap>
        {displayImage && (
          <VideoImage
            loading={isCriticalVideo ? 'eager' : 'lazy'}
            fetchpriority={isCriticalVideo ? 'high' : undefined}
            {...{ alt, imageDesktop, imageMobile }}
          />
        )}

        {video?.platform === InlineVideoPlatforms.DEFAULT_PLAYER && (
          <MaxIterationsVideo
            autoPlay
            imageWhenComplete={(isMediumOrLargeViewport ? imageDesktop : imageMobile) ?? undefined}
            isLazy
            maxIterations={5}
            muted
            playsInline
            src={video.src}
          />
        )}

        {video?.platform === InlineVideoPlatforms.VIMEO && (
          <VimeoVideo
            autoplay
            lazy
            thumbnail={
              <VideoImage
                loading={isCriticalVideo ? 'eager' : 'lazy'}
                fetchpriority={isCriticalVideo ? 'high' : undefined}
                {...{ alt, imageDesktop, imageMobile }}
              />
            }
            videoSrc={video.src}
          />
        )}
      </AssetWrap>

      <TextWrap {...{ textColor, textContentMaxWidthMobile, textContentMaxWidthDesktop }}>
        <TitleImage
          {...{ alt }}
          desktopSrc={titleImageDesktop}
          mobileSrc={titleImageMobile}
          loading='lazy'
        />
        {title && <Title isUppercase={isUppercase(title)}>{title}</Title>}
        {subtitle && <Subtitle isUppercase={isUppercase(subtitle)}>{subtitle}</Subtitle>}
        {hasButtonBar && (
          <StyledButtonBar>
            {linkText1 &&
              (hasModalVideo ? (
                <Button
                  variant={buttonVariant}
                  onClick={openVideoModal}
                  {...trackEvent({
                    category: 'video-section',
                    action: 'open video modal',
                    pageSectionIndex,
                    pageSectionName: title ?? undefined,
                  })}
                >
                  {linkText1}
                </Button>
              ) : linkHref1 ? (
                <MaybeSpaLinkWrapper href={linkHref1}>
                  <Button
                    as='a'
                    href={linkHref1}
                    variant={buttonVariant}
                    {...trackEvent({
                      category: 'video-section',
                      action: 'click cta 1',
                      label: linkText1,
                      value: linkHref1,
                      pageSectionIndex,
                      pageSectionName: title ?? undefined,
                    })}
                  >
                    {linkText1}
                  </Button>
                </MaybeSpaLinkWrapper>
              ) : null)}
            {linkText2 && linkHref2 && (
              <MaybeSpaLinkWrapper href={linkHref2}>
                <Button
                  as='a'
                  href={linkHref2}
                  variant={buttonVariant}
                  {...trackEvent({
                    category: 'video-section',
                    action: 'click cta 2',
                    label: linkText2,
                    value: linkHref2,
                    pageSectionIndex,
                    pageSectionName: title ?? undefined,
                  })}
                >
                  {linkText2}
                </Button>
              </MaybeSpaLinkWrapper>
            )}
          </StyledButtonBar>
        )}
      </TextWrap>

      {hasModalVideo && (
        <VideoModal
          isOpen={isVideoModalOpen}
          onClose={closeVideoModal}
          videoId={modalVideoId}
          videoPlatform={modalVideoPlatform}
        />
      )}
    </Body>
  )
}
