import VimeoIframeController from '@vimeo/player'
import React, { useEffect, useRef, useState } from 'react'

import { ScreenReaderOnly } from '@syconium/little-miss-figgy'

import { useFixturesContext } from '../../containers/fixtures'

import {
  Button,
  NonInteractiveThumbnailContainer,
  Pause,
  PlayIcon,
  VimeoIframe,
  VimeoWrapper,
} from './styles'

type VimeoVideoProps = {
  autoplay?: boolean
  lazy?: boolean
  thumbnail?: React.ReactNode
  title?: string
  videoSrc: string
  position?: string
  isInteractive?: boolean
  includePauseButton?: boolean
  objectPosition?: 'center' | 'top'
}

export const VimeoVideo: React.FC<VimeoVideoProps> = ({
  autoplay = false,
  isInteractive = true,
  lazy = false,
  thumbnail,
  title,
  videoSrc,
  position,
  includePauseButton = false,
  objectPosition = 'center',
}) => {
  const [isPaused, setIsPaused] = useState<boolean>(!autoplay)
  const {
    magnolia: { general },
  } = useFixturesContext()
  const play = function () {
    if (typeof iframeRef.current?.contentWindow?.postMessage === 'function') {
      iframeRef.current.contentWindow.postMessage({ method: 'play' }, 'https://player.vimeo.com')
    }
  }
  const pause = function () {
    if (typeof iframeRef.current?.contentWindow?.postMessage === 'function') {
      iframeRef.current.contentWindow.postMessage({ method: 'pause' }, 'https://player.vimeo.com')
    }
  }

  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const vimeoIframeControllerRef = useRef<VimeoIframeController | null>(null)
  const [isReady, setIsReady] = useState(false)
  const [aspectRatio, setAspectRatio] = useState(() => {
    return 16 / 9
  })

  useEffect(() => {
    let isMostRecentExecution = true
    setIsReady(false)

    const controller = vimeoIframeControllerRef.current
      ? vimeoIframeControllerRef.current
      : iframeRef.current?.src
      ? new VimeoIframeController(iframeRef.current)
      : undefined

    if (!controller) return

    const checkMediaAspectRatio = () => {
      // Until we have switched to using a video tag, there is some nuance with Vimeo iFrames.
      // Vimeo does not seem to allow the video tag within the iframe to cover the iframe element.
      // Instead of acting like `object-fit: cover`, it basically functions like `object-fit: contain`
      //
      // Since the iframe can be a different size and aspect ratio as the video content contained within
      // it. We can end up with gaps above and below the video but inside the iframe, which will give us
      // glimpses to FIGS placeholder images behind the iframe itself.
      //
      // Wait to mark the video as ready until we can measure its size to get its aspect ratio and
      // then force the iframe to match the video's aspect ratio, basically creating `object-fit: cover`
      // that we desire.
      controller.ready().then(() => {
        if (!isMostRecentExecution) return
        Promise.all([controller.getVideoWidth(), controller.getVideoHeight()])
          .then(dimensions => {
            if (isMostRecentExecution) {
              const [initialWidth, initialHeight] = dimensions
              setAspectRatio(initialWidth / initialHeight)
            }
          })
          .catch(error => {
            if (isMostRecentExecution) {
              console.error(error)
            }
          })
          .finally(() => {
            if (isMostRecentExecution) {
              setIsReady(true)
            }
          })
      })
    }

    checkMediaAspectRatio()
    controller.on('loaded', checkMediaAspectRatio)

    return () => {
      isMostRecentExecution = false
      controller.off('loaded', checkMediaAspectRatio)

      vimeoIframeControllerRef.current = null
      controller?.destroy()
    }
  }, [videoSrc])

  function togglePause() {
    if (isPaused) {
      play()
      setIsPaused(false)
    } else {
      pause()
      setIsPaused(true)
    }
  }

  return (
    <>
      {isInteractive ? (
        <>{thumbnail}</>
      ) : (
        <NonInteractiveThumbnailContainer position={position}>
          {thumbnail}
        </NonInteractiveThumbnailContainer>
      )}

      <VimeoWrapper>
        {includePauseButton && isReady ? (
          <Button type='button' onClick={togglePause}>
            <ScreenReaderOnly>
              {!isPaused ? general.pauseVideo : general.playVideo}
            </ScreenReaderOnly>
            {!isPaused ? <Pause /> : <PlayIcon />}
          </Button>
        ) : null}

        <VimeoIframe
          key={videoSrc}
          data-ot-ignore
          ref={iframeRef}
          aspectRatio={aspectRatio}
          isVisible={isReady}
          isInteractive={isInteractive}
          allow='autoplay; fullscreen; picture-in-picture'
          allowFullScreen
          frameBorder='0'
          loading={lazy ? 'lazy' : 'eager'}
          src={`${videoSrc}?autoplay=${autoplay}&loop=1&title=0&byline=0&portrait=0&controls=0&muted=1&autopause=0&dnt=1`}
          title={title}
          objectPosition={objectPosition}
        />
      </VimeoWrapper>
    </>
  )
}

export default VimeoVideo
