import styled, { css } from 'styled-components'

import { TypeStyle, fromMd, pageLayoutMaxWidth } from '@syconium/little-miss-figgy'
import {
  sectionGapDesktop,
  sectionGapMobile,
  sectionSideGapDesktop,
} from '@syconium/magnolia/src/brunswick/components/page-sections/styles'
import { headerHeight } from '@syconium/magnolia/src/brunswick/constants'

export const TitleBar = styled.div`
  align-items: center;
  border-bottom: 1px solid ${o => o.theme.color.deprecated.stoneLight};
  display: flex;
  height: ${headerHeight};
  margin: 0 ${o => o.theme.dimensions.layout.sidePadding.md}px 24px;

  ${fromMd} {
    margin: 0 ${sectionSideGapDesktop}px 46px;
  }
`

export const Body = styled.section<{
  index?: number
  layoutVariantMobile?: string
  showTitlebar: boolean
}>`
  box-sizing: border-box;
  margin: 0 auto ${sectionGapMobile}px;
  max-width: ${pageLayoutMaxWidth};
  position: relative;

  ${fromMd} {
    margin-bottom: ${sectionGapDesktop};
  }

  ${o =>
    o.index === 0 &&
    css`
      margin-bottom: ${o.layoutVariantMobile === 'slider' ? '0' : '24px'};
      margin-top: ${!o.showTitlebar && '24px'};

      ${fromMd} {
        margin-bottom: 24px;
      }

      ${TitleBar} {
        margin-bottom: 24px;
      }
    `}
`

export const Title = styled(TypeStyle.HeadlineSmall)`
  color: ${o => o.theme.color.deprecated.obsidian};
  flex: 1;
  margin-right: 12px;
`

export const Tabs = styled.ul`
  flex: 0;
`
