import React from 'react'

import { Body, Grid } from './styles'

export const ButtonBar: React.FC<React.HTMLAttributes<HTMLElement>> = ({ children, ...rest }) => {
  return (
    <Body {...rest}>
      <Grid>{children}</Grid>
    </Body>
  )
}
