import styled from 'styled-components'

export const VimeoWrapper = styled.div`
  position: absolute;
  inset: 0;
  overflow: hidden;
`
import { focusOutline, fromMd } from '@syconium/little-miss-figgy'

export interface VimeoIframeProps {
  isInteractive: boolean
  aspectRatio: number
  isVisible: boolean
  objectPosition: 'center' | 'top'
}

export const PlayIcon = styled.div`
  background: transparent;
  height: 34px;
  min-width: 34px;
  position: absolute;

  &::after {
    content: '';
    display: block;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 8px solid ${o => o.theme.color.deprecated.black.primary};
    height: 0;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-36%, -50%);
    width: 0;
  }
`

export const VimeoIframe = styled.iframe<VimeoIframeProps>`
  box-sizing: border-box;
  aspect-ratio: ${o => o.aspectRatio};
  opacity: ${o => (o.isVisible ? 1 : 0)};

  position: absolute;
  left: 50%;
  top: ${o => (o.objectPosition === 'center' ? '50%' : '0%')};
  transform: translate(-50%, ${o => (o.objectPosition === 'center' ? '-50%' : '0%')});

  min-height: 100%;
  min-width: 100%;

  z-index: ${o => (o.isInteractive === false ? -1 : 'auto')};
`

export const NonInteractiveThumbnailContainer = styled.div<{ position?: string }>`
  height: 100%;
  left: 0;
  position: ${o => o.position ?? 'absolute'};
  top: 0;
  width: 100%;
  z-index: -1;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  position: absolute;
  border-radius: 50%;
  background-color: ${o => o.theme.color.deprecated.stoneLighter};
  ${fromMd} {
    bottom: 24px;
    right: 22px;
  }
  bottom: 16px;
  right: 16px;
  z-index: 2;
  pointer-events: all;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  ${focusOutline};
`
export const PauseIcon = styled.div`
  display: flex;
  gap: 3px;
  max-width: 24px;
  max-height: 24px;
  position: relative;
`

export const PauseLine = styled.div`
  width: 2px;
  height: 13px;
  background-color: ${o => o.theme.color.deprecated.obsidian};
  border-radius: 1px;
`

export const Pause = () => (
  <PauseIcon>
    <PauseLine></PauseLine>
    <PauseLine></PauseLine>
  </PauseIcon>
)
